<template>
  <v-card
    class="d-flex flex-column align-center justify-center"
    :class="{ loading: loading }"
  >
    <button class="align-self-end">
      <v-icon @click="closeDialog">X</v-icon>
    </button>

    <h3 style="margin: 1rem; font-size: 1.2rem">Recuperar senha</h3>

    <input
      placeholder="Digite seu email"
      v-model="email"
      class="dialog-input"
    />
    <v-btn @click="submitInput" class="dialog-btn">RECUPERAR</v-btn>
    <div
      v-if="loading == true"
      style="position: absolute"
      class="d-flex align-center justify-center text-center"
    >
      <v-progress-circular :size="70" indeterminate color="red"></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      email: '',
      loading: false
    };
  },
  methods: {
    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    },
    async submitInput() {
      this.loading = true;
      const isValidEmail = this.validateEmail(this.email);

      if (!isValidEmail) {
        alert('Email inválido');
        this.loading = false;
        return;
      }

      await axios.post(`/auth/password/request`, {
        email: this.email,
        reset_url: 'https://mezzobar.com.br/cadastro-senha'
      });

      alert(
        'Caso o email esteja correto, um link de redefinição de senha foi enviado para o email informado'
      );

      this.email = '';
      this.loading = false;
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('close');
    }
  }
};
</script>

<style>
.v-progress-circular {
  margin: 1rem;
}
.dialog-input {
  border: 1px solid #77777770;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  margin: 10px;
  padding-left: 10px;
}
.v-card.loading {
  pointer-events: none;
}
.v-card {
  padding: 2rem;
  overflow-x: hidden;
  width: 100%;
}
.dialog-btn {
  margin: 10px 0;
}
</style>

