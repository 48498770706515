<template>
  <v-row id="clubeVip" class="fill-height pt-15 mx-0 align-center">
    <v-col cols="12" md="3" sm="1"></v-col>
    <v-col
      cols="12"
      md="6"
      sm="12"
      class="text-center padMobile"
      style="color: white; margin-top: 50px"
    >
      <h2 class="fontSizeMobile" style="font-weight: 200; font-family: poppins">
        Imagine a cena: você almoçando um prato delicioso enquanto aprecia a
        vista <b>INCRÍVEL</b> do primeiro mezanino a céu aberto de Sanja.
        <br />Incrível, né? Fica melhor ainda com
        <b>15% de desconto no almoço!</b> Preencha seu cadastro individual e garanta as condições de
        Parceiro Mezzo.
      </h2>
      <p>
        <small> *Necessário documento de identificação </small>
      </p>
    </v-col>
    <v-col cols="12" md="3" sm="1"></v-col>
    <v-col cols="12" md="3" sm="1"></v-col>
    <v-col cols="12" md="6" sm="12">
      <v-form :disabled="disabled" ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
              dark
                v-model="u.empresaNome"
                :counter="70"
                label="Nome da Empresa"
                required
                color="#8e1f3f"
                :rules="rule"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
              dark
                v-model="u.nome"
                :counter="70"
                label="Nome Completo"
                required
                color="#8e1f3f"
                :rules="rule"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
              dark
                v-model="u.cpf"
                :counter="70"
                label="CPF"
                v-mask="'###.###.###-##'"
                required
                color="#8e1f3f"
                :rules="rule"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
              dark
                v-model="u.email"
                required
                label="Email"
                color="#8e1f3f"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
              dark
                v-model="u.telefone"
                label="Celular"
                v-mask="'(##) # #### ####'"
                required
                color="#8e1f3f"
                :rules="rule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" align="center">
              <v-checkbox
              required
                color="#34000f"
                v-model="u.aceitoTermos"
                label="Aceito receber promoções e novidades do Mezzo Bar."
              ></v-checkbox>
            </v-col>
            <v-col cols="12" class="mb-8" align="center">
              <v-btn
                :loading="loading"
                :disabled="disabled"
                @click="enviarFormulario"
                color="#8e1f3f"
                class="white--text"
                >
                {{textBotao}}
                </v-btn
              >
            </v-col>
          </v-row>
          <v-alert v-model="vipCadastrado" elevation="3" type="success">
            Seu cadastro foi realizado com sucesso!
          </v-alert>
        </v-container>
      </v-form>
    </v-col>
    <v-col cols="12" md="3" sm="1"></v-col>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<style scoped>
@media (min-device-width: 360px) and (max-device-width: 730px) {
  .fontSizeMobile {
    font-size: 20px !important;
  }
  .padMobile {
    padding: 0 12px !important;
  }
}

#clubeVip {
  background-size: cover;
  background-position: top center;

  background: linear-gradient(
    180deg,
    rgb(52, 0, 15) 0%,
    rgb(142, 31, 63) 100%
  ) !important;
}
.v-form {
  background-color: #ffffff00;

  border-radius: 5px;
}
</style>


<script>
export default {
  name: "ClupeVip",
  data: () => ({
    loading: false,
    disabled: false,
    timeout: 5000,
    snackbar: false,
    text: "",
    textBotao: "Eu Quero o desconto!",
    vipCadastrado: false,
    valid: false,
    u: {},
    rule: [(value) => !!value || "Este campo é obrigatório."],
    emailRules: [
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (
          value === undefined ||
          value === "" ||
          pattern.test(value) ||
          "Preencha um email válido."
        );
      },
    ],
  }),
  methods: {
    enviarFormulario() {
      this.loading = true
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id_formulario: 39, lead: this.u }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.data.id) { 
            this.text = "Seu cadastro foi realizado com sucesso!"
            this.snackbar = true;
            this.textBotao = "Cadastrado com sucesso"
            this.loading = false 
            this.disabled = true
          }
          else { 
            this.loading = false
            this.text = "Erro ao realizar seu cadastro! Revise seus dados e tente novamente."
            this.snackbar = true;
          }
        }).catch(() => { 
   
          this.loading = false
          this.text = "Erro ao conectar ao servidor! Verifique sua conexão e tente novamente."
          this.snackbar = true;
        });
    },
  },
};
</script>