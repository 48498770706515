<template>
  <v-container fluid id="clubeVip">
    <v-row justify="center" class="fill-height mx-0 align-center">
      <v-col cols="12" md="3" class="text-center">
        <h2 class="white--text">Área Protegida</h2>
        <v-text-field
          filled
          color="#fff"
          v-model="user.email"
          label="Email"
          dark
          type="text"
          style="color: #fff"
        ></v-text-field>
        <v-text-field
          filled
          color="#fff"
          v-model="user.password"
          label="Senha"
          dark
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
        ></v-text-field>
        <p
          style="
            cursor: pointer;
            color: #fff;
            display: flex;
            align-items: start;
            margin-bottom: 1.2rem;
          "
          @click="showResetPasswordDialog = true"
        >
          Esqueci a minha senha
        </p>
        <v-btn @click="login">Entrar</v-btn>
        <router-link to="/cadastro">
          <p style="color: #fff; font-size:20px" class="mt-4">
            Ainda não tem uma conta? <b>Clique aqui</b>
          </p>
        </router-link>
      </v-col>
    </v-row>
    <v-dialog
      width="30rem"
      persistent
      style="overflow-x: hidden"
      v-model="showResetPasswordDialog"
    >
      <ResetPassword
        @close="showResetPasswordDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<style scoped>
#clubeVip {
  background: linear-gradient(
    180deg,
    rgba(52, 0, 15, 1) 0%,
    rgba(142, 31, 63, 1) 100%
  );
  height: 102vh;
}

#resetPassword {
  background: linear-gradient(
    180deg,
    rgba(52, 0, 15, 1) 0%,
    rgba(142, 31, 63, 1) 100%
  );
  height: 40vh;
  width: 40vw;
}

.v-form {
  background-color: #ffffff85;
  border-radius: 5px;
}
</style>
<script>
import ResetPassword from '../components/ResetPassword.vue';
export default {
  components: { ResetPassword },
  name: 'Login',
  data: () => ({
    user: { email: '', password: '' },
    // bd: [
    //   { email: "VITOR", senha: "M3zz0@V1t0r" },
    //   { email: "GABRIEL", senha: "M3zz0@G4br13l" },
    //   { email: "FABIO", senha: "M3zz0@F4b10" },
    //   { email: "EMILY", senha: "M3zz0@3m1ly" },
    //   { email: "SIDNEY", senha: "M3zz0@S1dn3y" },
    // ],
    showResetPasswordDialog: false,
    show1: false,
    resetFields: {}
  }),
  methods: {
    async login() {
      try {
        if (this.user && this.user.email && this.user.password) {
          await this.$store.dispatch('login', this.user);

          const fullAccess = this.$store.state.fullAccess;

          if (fullAccess === true) {
            this.$router.push('/panel');
          } else if (fullAccess === false) {
            this.$router.push('/perfil');
          } else {
            alert('Login failed');
          }
        }
      } catch (error) {
        if (error.message === 'Token expired.') {
          await this.login();
        } else {
          alert(error.message);
        }
      }

      // let emailExists = this.bd.find(el => {
      //     if ((el.email === this.user.email) && (el.senha === this.user.password)) {
      //         return true
      //     }
      // })

      // if (emailExists) {
      //     localStorage.setItem('tokenMezzo', true)
      //     this.$store.commit('auth_success', "true", "MezzoBar")
      //     this.$router.push("show-members")
      // }
      // else {
      //     alert("senha inválida!")
      // }
    },
    async getUserLogged() {
      try {
        const token = this.$store.state.token;
        if (token) {
          await this.$store.dispatch('getUserInfo');

          const fullAccess = this.$store.state.fullAccess;

          if (fullAccess === true) {
            this.$router.push('/panel');
          } else if (fullAccess === false) {
            this.$router.push('/perfil');
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  beforeMount() {
    this.getUserLogged();
  }
};
</script>

