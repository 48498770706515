<template>
  <v-container fluid>
    <v-row
      class="Pt1"
      style="padding: 0px !important; height: 90vh !important"
    >
    <v-col
        cols="12"
        md="2"
      ></v-col>
      <v-col
        cols="12"
        md="4"
        class="d-flex justify-center  flex-column"
      >
        <div style="" class=" ">
          <h2 style="font-family: Poppins; color: #fff; font-weight: 500">
            Endereço
          </h2>
        </div>
        <p style="font-family: Poppins; color: #fff">
          Avenida Cassiano Ricardo, 319 <br />
          Jardim Aquarius<br />
          São José dos Campos | SP<br />
          Telefone: +55 12 3941-9007
        </p>
      </v-col>

      <v-col cols="12" md="6" class="d-flex justify-center align-center">
        <video
          width="100%"
          src="https://mezzobar.com.br/media/mezzobar.mp4"
          autoplay
          controls
          muted
        ></video>
      </v-col>
      <!-- <v-img height="100vh" width="100" src="../assets/banner2.png"></v-img> -->
    </v-row>
    <!-- <v-row>
      <router-link to="/cardapio">
        <v-img
          class="hover"
          width="150"
          style="position: absolute; left: 15px; top: 200px; cursor: pointer"
          src="../assets/menu cardapio.png"
        ></v-img>
      </router-link>
      <router-link to="/localizacao">
        <v-img
          class="hover2"
          width="150"
          style="position: absolute; left: 15px; top: 300px; cursor: pointer"
          src="../assets/localizacao menu.png"
        ></v-img>
      </router-link>
    </v-row> -->
    <v-row class="Pt1">
      <v-col cols="4" md="4" class="align-center justify-center d-flex" >
        <a href="https://waze.com/ul/h6gz7gq181 " target="_blank">
        <div class="paddingDiv" style="border: 1px solid #fff; border-radius:50px; padding: 5px 35px">
        <v-img max-width="50px" max-height="50px" style="" src="../assets/waze.png"></v-img>
        </div>
        </a>
      </v-col>
      <v-col cols="4" md="4" class="align-center justify-center d-flex" >
        <a href="https://goo.gl/maps/RSL5EZeXK2Yy19AC9" target="_blank">
        <div class="paddingDiv" style="border: 1px solid #fff; border-radius:50px; padding: 5px 35px">
        <v-img max-width="50px" max-height="50px" style="" src="../assets/maps.png"></v-img>
        </div>
        </a>
      </v-col>
      <v-col cols="4" md="4" class="align-center justify-center d-flex">
        <a href="https://m.uber.com/ul/?action=setPickup&client_id=l1E_iGs-VEDeLoIL4vheuoyUzHG9LNKj&pickup=my_location&dropoff[formatted_address]=Avenida%20Cassiano%20Ricardo%2C%20319%20-%20Jardim%20Aquarius%2C%20S%C3%A3o%20Jos%C3%A9%20dos%20Campos%20-%20SP%2C%20Brasil&dropoff[latitude]=-23.214136&dropoff[longitude]=-45.909306" target="_blank">
        <div class="paddingDivUber" style="border: 1px solid #fff; border-radius:100px;padding: 16px 35px">
        <v-img max-width="70px" max-height="70px" style="" src="../assets/uber.png"></v-img>
        </div>
        </a>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.7605992895133!2d-45.91183098555217!3d-23.215393654790685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cc3583f22d27cf%3A0x29bf5e4bf8f4b5b8!2sAv.%20Cassiano%20Ricardo%2C%20319%20-%20Parque%20Res.%20Aquarius%2C%20S%C3%A3o%20Jos%C3%A9%20dos%20Campos%20-%20SP%2C%2012246-000!5e0!3m2!1spt-BR!2sbr!4v1642006819518!5m2!1spt-BR!2sbr"
        width="100%"
        height="150"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </v-row>

    <v-row
      class="bgcFooter"
      justify="center"
      align="center"
      style="padding: 40px 0"
    >
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center flex-column"
        style="padding: 20px 40px"
      >
        <h2 style="color: #fff; font-family: Poppins; font-weight: 500">
          Horário de Funcionamento
        </h2>
        <p
          style="
            color: #fff;
            font-family: Poppins;
            font-weight: 300;
            font-size: 14px;
          "
        >
          TERÇA: 11:50h às 15:00h e das 17:00h às 22:00h <br />
          QUARTA E QUINTA: 11:50h às 15:00h e das 17:00h às 23:00h <br />
          SEXTA: 11:50h às 15:00h e das 17:00h às 00:00h <br />
          SÁBADO: 12:00h às 00:00h <br />
          DOMINGO: 12:00h às 17:00h
        </p>
        <h3 style="color: #fff; font-family: Poppins; font-weight: 300">
          Telefone: (12) 99641-2263
        </h3>
      </v-col>

      <v-col cols="12" md="6" style="padding: 0px 60px">
        <h3 style="font-family: poppins; font-weight: 400; color: #fff">
          Críticas e sugestões
        </h3>
        <v-form ref="form" lazy-validation dark>
          <v-text-field dark :counter="10" label="Nome" required></v-text-field>

          <v-text-field dark label="E-mail" required></v-text-field>

          <v-text-field dark
          v-mask="'(##) #####-####'"
            :counter="7"
            label="Telefone"
            required
          ></v-text-field>

          <v-textarea dark :counter="7" label="Mensagem" required></v-textarea>

          <v-btn
            block
            class="mr-4 align-center justify-center d-flex"
            @click="enviarFormulario"
            style="color: #8e1f3f; font-family: Poppins"
          >
            Enviar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-row style="background-color: #000">
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <p
          style="
            font-size: 12px;
            color: #fff;
            text-align: left;
            margin-bottom: 0px;
          "
        >
          MEZZO BAR E RESTAURANTE 2021 – Todos direitos reservados.
        </p>
      </v-col>

      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <v-btn
          @click="politica = true"
          style="
            font-size: 12px;
            color: #fff;
            text-align: center;
            margin-bottom: 0px;
            background-color: transparent;
            color: #fff !important;
            font-family: poppins;
          "
        >
          Politica de privacidade
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="4"
        class="d-flex justify-center align-center flex-column"
      >
        <v-img
          style="width: 40px; height: 40px"
          src="../assets/abareBranco.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    timeout:2000,
    snackbar: false,
    text: "Seu contato foi enviado! Obriado por sua mensagem, responderemos em breve.",
    imgDialog: "",
    politica: false,
    days: ['TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SÁBADO', 'DOMINGO'],
    mobileDays: ['TER', 'QUA', 'QUI', 'SEX', 'SÁB', 'DOM'],
    contato: { nome: "", email: "", mensagem: "", telefone: "" },
  }),
  computed: { 

    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    }
  },
  methods: {
    getDay(intDay) { 
      if (this.intBreakPoint > 1) { 
        return this.days[intDay]
      }
      else { 
        return this.mobileDays[intDay]
      }
    },
    enviarFormulario() {
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id_formulario: 36, lead: this.contato }),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          // alert("contato enviado com sucesso");
          this.dialog = false;
          this.snackbar = true;
        });
    },
  }
}
</script>

<style scoped>
@media (min-device-width: 360px) and (max-device-width: 730px) {
  .paddingDiv{
    padding: 16px 20px !important;
  }
  .paddingDivUber{
    padding: 30px 15px !important;
  }
}

.bgcFooter {
  background: linear-gradient(
    180deg,
    rgba(52, 0, 15, 1) 0%,
    rgba(142, 31, 63, 1) 100%
  );
}
.Pt1 {
  /* background-image: url("../assets/banner2.webp"); */
  background-color: #570C22;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>