<template>
	<v-container fluid class="page">
		<v-row class="header d-flex align-center">
			<v-col
				cols="12"
				md="6"
				style="font-family: 'Segoe UI', sans-serif"
				class="d-flex align-center justify-center flex-column"
			>
				<div style="margin: 50px 0; text-align: left">
					<v-img
						contain
						style="max-width: 120px"
						src="../assets/logo.png"
					></v-img>
					<h2>
						A culinária exclusiva do Mezzo entregue em sua casa para
						a
					</h2>
					<h1 class="titulo-principal titulo-principal-mobile">
						Ceia de Natal
					</h1>
					<a
						href="https://wa.me/5512996412263?text=Quero%20saber%20sobre%20a%20ceia!"
						target="_blank"
					>
						<v-btn rounded elevation="2" class="btn btnMobile"
							><v-icon color="green" size="40px"
								>mdi-whatsapp</v-icon
							>QUERO A CEIA DO MEZZO!</v-btn
						>
					</a>
				</div>
			</v-col>
			<v-col cols="12" md="6">
				<v-img
					contain
					src="../assets/lpNatal/comidaHeader.png"
					class="comida"
				></v-img>
			</v-col>
		</v-row>

		<v-row
			class="conteudo d-flex flex-column align-center justify-center"
			style="text-align: center"
		>
			<v-col cols="12" md="12">
				<h1 class="titulo-principal titulo-principal-mobile cor-mezzo">
					Cardápio
				</h1>
				<p class="cor-mezzo" style="font-weight: bold">
					Completo para tornar sua Ceia de Natal Unica,<br />Exclusiva
					e muito Saborosa.
				</p>
			</v-col>
		</v-row>

		<v-row style="text-align: left; padding: 3vh 3vw">
			<v-col cols="6" md="6" style="padding-left: 5vw">
				<h3>Tábua de Frios</h3>
			</v-col>
			<v-col cols="6" md="6" style="padding-left: 5vw">
				<h3>Acompanhamentos</h3>
			</v-col>
		</v-row>

		<v-row style="text-align: left; padding: 3vh 3vw" class="conteudo">
			<v-col cols="6" md="6">
				<ul>
					<li>Mel trufado, Tomate cereja confitado,</li>
					<li>Azeitonas Pretas, Gorgonzola, Parmesão,</li>
					<li>Salame, Presunto Parma, Muçarela de Búfala</li>
					<li>Queijo Ementtal, Geleia de Frutas Vermelhas,</li>
					<li>Caponata de Berinjela e</li>
					<li>Pão Artesanal</li>
				</ul>
			</v-col>
			<v-col cols="6" md="6">
				<ul>
					<li>Farofa com damasco, amêndoas e bacon</li>
					<li>Farofa com bacon e azeitonas</li>
					<li>Arroz com bacalhau e brócolis</li>
					<li>Arroz com açafrão e frutas secas</li>
					<li>Salpicão de frango, maçãs e frutas secas</li>
				</ul>
			</v-col>
		</v-row>

		<v-row style="text-align: center; padding: 3vh 3vw">
			<v-col cols="12" md="12">
				<h1>Pratos</h1>
			</v-col>
		</v-row>

		<v-row style="text-align: left; padding: 3vh 3vw" class="conteudo">
			<v-col cols="6" md="6">
				<ul>
					<li>
						Ravioli de muçarela de búfala com molho de tomate
						rústico
					</li>
					<li>Tender (pernil) ao molho de maracujá e gengibre</li>
					<li>
						Filé mignon suíno com champignon e creme de mostarda
					</li>
					<li>
						Escalope de filé mignon ao molho funghi e queijo Brie
					</li>
				</ul>
			</v-col>
			<v-col cols="6" md="6">
				<ul>
					<li>Coxa e sobrecoxa recheada com farofa de cogumelos</li>
					<li>
						Bacalhau a lagareiro, azeitonas, ervilha torta, batata e
						alho assado, regado no azeite
					</li>
					<li>
						Saint Peter ao mediterrâneo, azeitonas pretas, castanha
						de caju, tomate confit, cebola e manjericão, regado no
						azeite
					</li>
				</ul>
			</v-col>
		</v-row>

		<v-row style="text-align: center; padding: 3vh 3vw">
			<v-col cols="12" md="12">
				<h1>Sobremesas</h1>
			</v-col>
		</v-row>

		<v-row style="text-align: left; padding: 3vh 3vw" class="conteudo">
			<v-col cols="6" md="6">
				<ul>
					<li>Mousse de Chocolate</li>
				</ul>
			</v-col>
			<v-col cols="6" md="6">
				<ul>
					<li>Cheesecake de Chocolate com Calda Toffee</li>
				</ul>
			</v-col>
		</v-row>

		<v-row
			style="text-align: center; margin-top: 0; padding-top: 5vh"
			class="conteudo"
		>
			<v-col cols="12" md="12">
				<a
					href="https://wa.me/5512996412263?text=Quero%20saber%20sobre%20a%20ceia!"
					target="_blank"
				>
					<v-btn rounded elevation="2" class="btn btnMobile"
						><v-icon color="green" size="40px">mdi-whatsapp</v-icon
						>Quero a ceia do Mezzo!</v-btn
					></a
				>
				<br />
				<br />
				<p class="cor-mezzo" style="font-weight: bold">
					*Opção por KG ou quantidade de pessoas<br />
					**Escolha a melhor opção.
				</p>
			</v-col>
		</v-row>

		<v-row class="footer d-flex align-center" style="padding-top: 50px">
			<v-col cols="12" md="6" class="d-flex flex-column">
				<v-img
					contain
					style="max-width: 20vw; margin: 0; padding: 0"
					src="../assets/logo.png"
				></v-img>
				<h1 class="cor-secundaria">
					Se preocupe apenas em reunir a família e os amigos
				</h1>
				<p>
					Nós deixamos esse momento ainda mais especial com a nossa
					culinária exclusiva, entregue em sua casa.
				</p>
				<a
					href="https://wa.me/5512996412263?text=Quero%20saber%20sobre%20a%20ceia!"
					target="_blank"
				>
					<v-btn rounded elevation="2" class="btn btnMobile" id="btn"
						><v-icon color="green" size="40px">mdi-whatsapp</v-icon>
						Entre em contato agora!</v-btn
					></a
				>
			</v-col>
			<v-col cols="12" md="6">
				<v-img
					class="comida"
					contain
					src="../assets/lpNatal/comidaFooter.png"
				></v-img>
			</v-col>
		</v-row>

		<v-row class="d-flex align-center" style="text-align: center">
			<v-col cols="12" md="6">
				<h1 style="color: var(--secondaryColor)">
					<v-icon size="40px" color="var(--secondaryColor)"
						>mdi-whatsapp</v-icon
					>
					(12) 99641-2263
				</h1>
			</v-col>
			<v-col cols="12" md="6">
				<h1 style="color: var(--secondaryColor)">
					<v-icon size="40px" color="var(--secondaryColor)"
						>mdi-instagram</v-icon
					>
					@mezzobarsjc
				</h1>
			</v-col>
		</v-row>

		<v-row style="background-color: #000; height: 100%; padding: 2vh 2vw">
			<v-col
				cols="12"
				md="6"
				class="justify-center d-flex align-content-end"
				style="padding: 20px 0px 0px 0px"
			>
				<p
					class="pdLeftMb"
					style="
						color: #fff;
						font-size: 15px;
						font-weight: 300;
						padding-left: 50px;
					"
				>
					MEZZOBAR | Todos os direitos reservados 2022
				</p>
			</v-col>
			<v-col
				cols="12"
				md="6"
				class="justify-center d-flex align-content-start"
				style="padding: 0px"
			>
				<v-img
					contain
					style="max-width: 50px"
					src="../assets/abareBranco.png"
				></v-img>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		name: "LpCopa",
		data: () => ({}),
		computed: {
			intBreakPoint() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 0;
					case "sm":
						return 1;
					case "md":
						return 2;
					case "lg":
						return 3;
					case "xl":
						return 4;
					default:
						return 0;
				}
			},
		},
	};
</script>



<style scoped>
	@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Inter:wght@400;600;700&display=swap");

	@media (min-device-width: 360px) and (max-device-width: 730px) {
		.titulo-principal-mobile {
			font-size: 4.5em !important;
		}
		.btnMobile {
			font-size: 16px !important;
			margin: 20px 0 20px 0 !important;
		}
	}

	* {
		--mainColor: #65172d;
		--secondaryColor: #efbe65;
		margin: 0;
		padding: 0;
		font-family: "Exo", "Roboto", sans-serif;
	}

	.cor-mezzo {
		color: var(--mainColor) !important;
	}

	.cor-secundaria {
		color: var(--secondaryColor);
	}

	.header {
		max-height: 100%;
		max-width: 100%;
		background-image: url("../assets/lpNatal/mezzoFundo1.png");
		background-size: auto;
		background-position: center;
		padding: 5vh 0 5vh 5vw;
	}

	.titulo-principal {
		font-family: "DancingScript", "Roboto", sans-serif !important;
		color: var(--secondaryColor);
		font-size: 75px;
	}

	.comida {
		max-height: 100vh;
	}

	.conteudo {
		background-image: url("../assets/lpNatal/contentFundo.png");
		background-position: center;
	}

	.titulo-principal {
		font-size: 8em;
		font-family: "DancingScript", "Roboto", sans-serif !important;
	}

	.page {
		color: #fff;
		background-color: var(--mainColor) !important;
	}

	.footer {
		margin-top: 0;
		padding: 5vh 0 5vh 5vw;
		background-image: url("../assets/lpNatal/mezzoFundo2.png") !important;
		background-size: auto;
		background-position: bottom;
	}

	.btn {
		font-size: 25px;
		font-weight: bold;
		background-color: #fff;
		color: var(--mainColor);
	}
</style>