<template>
  <v-row style="height: 102vh" justify="center" align="center" class="bgc3">
    <v-col cols="12" md="12" class="d-flex align-center flex-column" style="">
      <v-card style="background-color: #8e1f3ec7; padding: 20px">
        <h2
          class="mb-5"
          style="color: #fff; font-family: exo2; font-weight: 500"
        >
          Bem vindo ao Clube Mezzo
        </h2>
        <p style="color: #fff">Digite seu CPF para finalizar seu cadastro</p>
        <v-form class="mt-5">
          <v-text-field
            v-model="cpf"
            outlined
            dark
            type="text"
            v-mask="'###.###.###-##'"
            placeholder="###.###.###-##"
          ></v-text-field>
          <v-btn
            @click="checkUser"
            block
            style="color: #8e1f3f; background-color: #ffffffce"
            >Enviar</v-btn
          >
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.bgc3 {
  background-image: url('../assets/bannerStatic.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>

<script>
import axios from 'axios';
import store from '../store';

export default {
  data: () => ({
    cpf: null,
    mostrarNovaSenha: false,
    mostrarConfirmaSenha: false
  }),
  methods: {
    async checkUser() {
      try {
        if (!this.cpf) {
          throw new Error('Digite um CPF valido!');
        }

        const {
          data: { data }
        } = await axios.get(`/users?filter[document][_eq]=${this.cpf}`, {
          headers: {
            Authorization: `Bearer Df1Lordmn0PektIskl5GB5_PjnnVc4aV`
          }
        });

        let foundUser;
        for (let user of data) {
          if (user.password === null) {
            foundUser = user;
            break;
          }
        }

        if (!foundUser && data.length < 1) {
          throw new Error('Usuário não encontrado!');
        }

        if (!foundUser && data.length >= 1) {
          throw new Error('Usuário já possui senha cadastrada.');
        }

        store.state.user = foundUser;

        this.$router.push({
          name: 'cadastroSenha'
        });
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    }
  }
};
</script>
