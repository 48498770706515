<template>
	<v-container fluid>
		<v-row class="heightRowMb" style="background-color: #fff; height: 15vh">
			<v-col
				cols="12"
				md="4"
				class="align-center justify-center d-flex"
				style="margin-top: 0px"
			>
				<v-img
					contain
					style="max-width: 100px"
					src="../assets/lpCopa/logo.png"
				></v-img>
			</v-col>
			<v-col
				cols="12"
				md="8"
				class="align-center justify-center d-flex flexColumnMb"
			>
				<v-btn
					@click="goto('id1')"
					text
					style="
						color: #326103;
						text-transform: none;
						font-size: 20px;
					"
					>Atrações</v-btn
				>
				<!-- <a href="https://api.whatsapp.com/send?phone=+5512996412263&text=Ola%20gost%C3%A1ria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Mezzo%20Bar" target="/blank"> -->
				<v-btn
					@click="goto('id1')"
					text
					style="
						color: #326103;
						text-transform: none;
						font-size: 20px;
					"
					>Quero Participar</v-btn
				>
				<!-- </a> -->
				<v-btn
					@click="goto('id2')"
					text
					style="
						color: #326103;
						text-transform: none;
						font-size: 20px;
					"
					>Localização</v-btn
				>
			</v-col>
		</v-row>

		<v-row class="fundoTopo">
			<v-col
				cols="12"
				md="12"
				class="
					align-center
					justify-center
					d-flex
					flex-column
					align-self-end
				"
			>
				<h1
					class="fontTituloMobile"
					style="
						color: #fff;
						font-size: 130px;
						font-weight: 300;
						text-shadow: 8px 8px 8px #000;
					"
				>
					#MezzoNaCopa
				</h1>
				<a
					href="https://api.whatsapp.com/send?phone=+5512996412263&text=Ola%20gost%C3%A1ria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Mezzo%20Bar"
					target="/blank"
				>
					<v-btn
						ref="id3"
						class="btnMobile"
						style="
							background-color: #fff;
							box-shadow: none;
							border-radius: 50px;
							text-transform: none;
							font-weight: 800;
							font-size: 30px;
							padding: 40px 30px;
							color: #326103;
						"
						>Quero <br v-if="intBreakPoint < 2" />participar!</v-btn
					>
				</a>
			</v-col>
		</v-row>

		<v-row style="background-color: #fff; height: 3vh"></v-row>

		<v-row class="fundoMeio" style="">
			<v-col
				cols="12"
				md="12"
				class="align-center d-flex flex-column"
				ref="id1"
			>
				<h1
					style="
						color: #fff;
						font-size: 60px;
						font-weight: 800;
						line-height: 1;
						text-shadow: 8px 8px 8px #3a3a3a8f;
					"
				>
					Principais atrações
				</h1>
			</v-col>

			<v-col
				cols="12"
				md="6"
				class="align-center justify-center d-flex flex-column"
			>
				<v-card style="height: 400px; width: 350px; padding: 20px">
					<v-img
						contain
						style="max-height: 360px"
						src="../assets/lpCopa/nanda.png"
					></v-img>
				</v-card>
			</v-col>

			<v-col
				cols="12"
				md="6"
				class="align-center justify-center d-flex flex-column"
			>
				<v-card style="height: 400px; width: 350px; padding: 20px">
					<v-img
						contain
						style="max-height: 360px"
						src="../assets/lpCopa/jogoCopa.png"
					></v-img>
				</v-card>
			</v-col>

			<v-col cols="12" md="12" class="align-center justify-center d-flex">
				<a
					href="https://api.whatsapp.com/send?phone=+5512996412263&text=Ola%20gost%C3%A1ria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Mezzo%20Bar"
					target="/blank"
				>
					<v-btn
						class="btnMobile"
						style="
							background-color: #fff;
							box-shadow: none;
							border-radius: 50px;
							text-transform: none;
							font-weight: 800;
							font-size: 25px;
							margin-bottom: 20px;
							padding: 30px 20px;
							color: #326103;
						"
						>Quero <br v-if="intBreakPoint < 2" />participar!</v-btn
					>
				</a>
			</v-col>
		</v-row>

		<v-row style="background-color: #fff; height: 3vh"></v-row>

		<v-row class="fundoPratos">
			<v-col
				cols="12"
				md="12"
				class="d-flex flex-column"
				style="padding: 10vh 0"
			>
				<h1
					style="
						color: #fff;
						font-size: 60px;
						font-weight: 700;
						line-height: 1;
						padding-left: 20px;
						text-shadow: 8px 8px 8px #272727c5;
						text-align: center;
					"
				>
					Pratos exclusivos
				</h1>
			</v-col>
			<v-col
				cols="12"
				md="12"
				class="d-flex flex-column"
				style="color: #fff"
			>
				<v-card class="cardCarousel">
					<ul>
						<li>
							<h1>Drinks e Chopp</h1>
						</li>
					</ul>
					<v-carousel
						hide-delimiters
						show-arrows-on-hover
						cycle
						interval="5000"
						class="
							d-flex
							flex-column
							align-center
							justify-content-center
						"
					>
						<v-carousel-item
							><v-img
								contain
								src="../assets/lpCopa/bebida.png"
								style="max-width: 100vw; padding-top: 20vh"
							></v-img
						></v-carousel-item>
						<v-carousel-item
							><v-img
								v-if="intBreakPoint > 2"
								contain
								src="../assets/lpCopa/bebida2.png"
								style="max-width: 100vw; padding-top: 10vh"
							></v-img
							><v-img
								v-else
								contain
								src="../assets/lpCopa/bebida2.png"
								style="max-width: 100vw; padding-top: 10vh"
							></v-img
						></v-carousel-item>
						<v-carousel-item
							><v-img
								contain
								src="../assets/lpCopa/bebida3.png"
								style="max-width: 100vw; padding-top: 20vh"
							></v-img
						></v-carousel-item>
					</v-carousel>
				</v-card>
			</v-col>
			<v-col
				cols="12"
				md="12"
				class="d-flex flex-column"
				style="color: #fff"
			>
				<v-card class="cardCarousel">
					<ul>
						<li><h1>Espetinhos e Sanduíches</h1></li>
					</ul>
					<v-carousel
						hide-delimiters
						show-arrows-on-hover
						cycle
						interval="5000"
						class="
							d-flex
							flex-column
							align-center
							justify-content-center
						"
					>
						<v-carousel-item
							><v-img
								v-if="intBreakPoint > 2"
								contain
								src="../assets/lpCopa/churrasco.png"
								style="max-width: 100vw"
							></v-img>
							<v-img
								v-else
								contain
								src="../assets/lpCopa/churrasco.png"
								style="max-width: 100vw; padding-top: 10vh"
							></v-img
						></v-carousel-item>
						<v-carousel-item
							><v-img
								contain
								src="../assets/lpCopa/churrasco2.png"
								style="max-width: 100vw; padding-top: 20vh"
							></v-img
						></v-carousel-item>
						<v-carousel-item
							><v-img
								contain
								src="../assets/lpCopa/pao.png"
								style="max-width: 100vw; padding-top: 20vh"
							></v-img
						></v-carousel-item>
					</v-carousel>
				</v-card>
			</v-col>
		</v-row>

		<v-row style="background-color: #fff; height: 3vh"></v-row>

		<v-row class="fundoFinal">
			<v-col
				cols="12"
				md="5"
				class="align-center justify-center d-flex flex-column"
				ref="id2"
			>
				<div class="align-content-start">
					<v-img
						contain
						style="max-width: 90px"
						src="../assets/lpCopa/logoBranco.png"
					></v-img>
					<p
						style="
							margin-top: 20px;
							color: #fff;
							font-size: 23px;
							font-weight: 300;
						"
					>
						Endereço<br />
						Avenida Cassiano Ricardo, 319 <br />
						Jardim Aquarius <br />
						São José dos Campos | SP <br />
						Telefone: +55 12 3941-9007
					</p>
				</div>
			</v-col>
			<v-col
				cols="12"
				md="7"
				class="align-center justify-center d-flex flex-column"
			>
				<v-img
					contain
					style="width: 600px"
					src="../assets/lpCopa/externo.png"
				></v-img>
			</v-col>
		</v-row>

		<v-row style="background-color: #fff; height: 3vh"></v-row>

		<v-row
			class="heightRowMb marginFooter"
			style="background-color: #000; height: 10vh; margin: -18px"
		>
			<v-col
				cols="12"
				md="6"
				class="justify-center d-flex align-content-end"
				style="padding: 20px 0px 0px 0px"
			>
				<p
					class="pdLeftMb"
					style="
						color: #fff;
						font-size: 15px;
						font-weight: 300;
						padding-left: 50px;
					"
				>
					MEZZOBAR | Todos os direitos reservados 2022
				</p>
			</v-col>
			<v-col
				cols="12"
				md="6"
				class="justify-center d-flex align-content-start"
				style="padding: 0px"
			>
				<v-img
					contain
					style="max-width: 50px"
					src="../assets/abareBranco.png"
				></v-img>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		name: "App",
		data: () => ({}),
		methods: {
			goto(refName) {
				var element = this.$refs[refName];
				var top = element.offsetTop;

				window.scrollTo(0, top);
				this.snackbar2 = true;
			},
		},
		computed: {
			intBreakPoint() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 0;
					case "sm":
						return 1;
					case "md":
						return 2;
					case "lg":
						return 3;
					case "xl":
						return 4;
					default:
						return 0;
				}
			},
		},
	};
</script>

<style scoped>
	@media (min-device-width: 360px) and (max-device-width: 730px) {
		.fontTituloMobile {
			font-size: 50px !important;
		}

		.btnMobile {
			font-size: 25px !important;
			padding: 30px 20px !important;
		}
		.heightRowMb {
			height: 35vh !important;
		}
		.flexColumnMb {
			flex-direction: column !important;
		}
		.pdLeftMb {
			padding-left: 0px !important;
		}

		.marginFooter {
			margin: -12px !important;
		}

		.cardCarousel {
			background-color: rgba(255, 255, 255, 0.13);
			height: 80% !important;
		}
	}

	.fundoTopo {
		background-image: url("../assets/lpCopa/topoBg.png");
		background-color: #570c22;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100vh;
		background-position: center;
	}

	.fundoMeio {
		background-image: url("../assets/lpCopa/fundo.png");
		background-color: #570c22;
		background-repeat: no-repeat;
		background-size: cover;
		height: auto;
		background-position: center;
	}
	.fundoPratos {
		background-image: url("../assets/lpCopa/fundoPratos.png");
		background-color: #570c22;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		background-position: center;
	}
	.fundoFinal {
		background-image: url("../assets/lpCopa/bgMezzo.png");
		background-color: #570c22;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100vh;
		background-position: center;
	}
	.cardCarousel {
		background-color: rgba(255, 255, 255, 0.13);
		color: #fff;
		height: 100% !important;
	}
</style>