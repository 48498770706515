<template>
  <v-row id="clubeVip" style="padding: 120px 20px" class=" ">
    <v-col cols="12" sm="6" class="text-left" style="color: white">
      <h2 style="font-weight: normal">
        Receba as melhores promoções e vouchers do Mezzo Bar participando do
        nosso Clube Mezzo.<br />
        Basta preeencher os dados e clicar
        <span style="color: white; font-weight: bolder">Quero ser do Clube</span
        >!
      </h2>
      <!-- <p>
        Após ingressar no clube, retire seu voucher em nossa loja fisica no
        aquarios.
      </p> -->
      <!-- <p>
        <small>
          *Obs: Para retirada do voucher é necessario consumação R$30,00
        </small>
      </p> -->
    </v-col>
    <v-col cols="12" sm="6" style="width: 90%;">
      <v-card >
        <v-form ref="form" v-model="valid" light lazy-validation style="">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  light
                  :items="companies"
                  v-model="user.company"
                  dense
                  outlined
                  required
                  :rules="rule"
                  label="Empresas parceiras"
                  style="color: #8e1f3f !important"
                  item-value="id"
                  item-text="name"
                  v-if="formCreateCompany == false"
                ></v-select>
                <span v-if="!companyAlreadyCreated"
                  >Não encontrou sua empresa ?
                  <v-btn
                    style="color: #8e1f3f"
                    @click="formCreateCompany = !formCreateCompany"
                    text
                    >Clique aqui</v-btn>
                  para ser um parceiro</span>
              </v-col>
              <v-col
                class="d-flex align-end justify-center"
                style="gap: 1rem"
                v-if="formCreateCompany"
                cols="12"
              >
                <v-text-field
                  hide-details
                  dense
                  outlined
                  light
                  v-model="company.name"
                  label="Nome da empresa"
                >
                </v-text-field>
                <v-btn
                  class="mt-2"
                  style="
                    background-color: transparent;
                    border: 1px solid #8e1f3f;
                    box-shadow: none;
                    border-radius: 50px;
                  "
                  @click="createCompany"
                  >Adicionar</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  dense
                  v-model="user.full_name"
                  :counter="70"
                  required
                  label="Nome completo"
                  outlined
                  :rules="rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  light
                  dense
                  outlined
                  v-model="user.document"
                  :counter="70"
                  label="CPF"
                  v-mask="'###.###.###-##'"
                  required
                  color="#8e1f3f"
                  :rules="rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  light
                  dense
                  outlined
                  v-model="birthday"
                  label="Data de nascimento"
                  v-mask="'##/##/####'"
                  required
                  color="#8e1f3f"
                  :rules="rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  light
                  dense
                  outlined
                  v-model="user.email"
                  label="Email"
                  required
                  color="#8e1f3f"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  light
                  dense
                  outlined
                  v-model="user.password"
                  label="Senha"
                  type="password"
                  required
                  color="#8e1f3f"
                  :rules="rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  light
                  dense
                  outlined
                  v-model="confirmPassword"
                  label="Confirmar senha"
                  type="password"
                  required
                  color="#8e1f3f"
                  :rules="rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  light
                  dense
                  outlined
                  v-model="user.phone"
                  label="Celular"
                  v-mask="'(##) # #### ####'"
                  required
                  color="#8e1f3f"
                  :rules="rule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" align="center">
                <v-checkbox
                  color="#34000f"
                  v-model="user.termos"
                  label="Aceito receber promoções e novidades do Mezzo Bar."
                ></v-checkbox>
              </v-col>
              <v-col cols="12" class="mb-8" align="center">
                <v-btn
                  :disabled="loading"
                  @click="createUser"
                  color="#8e1f3f"
                  class="white--text"
                  >Eu Quero o Clube Mezzo</v-btn
                >
              </v-col>
            </v-row>
            <v-alert v-model="vipCadastrado" elevation="3" type="success">
              Seu cadastro foi realizado com sucesso!
            </v-alert>
          </v-container>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
#clubeVip {
  background-size: cover;
  background-position: top center;

  background: linear-gradient(
    180deg,
    rgb(52, 0, 15) 0%,
    rgb(142, 31, 63) 100%
  ) !important;
}

.v-form {
  background-color: #ffffff00;

  border-radius: 5px;
}
</style>
<script>
import axios from 'axios';

export default {
  name: 'Cadastro',
  data: () => ({
    loading: false,
    vipCadastrado: false,
    valid: false,
    companyAlreadyCreated: false,
    formCreateCompany: false,
    confirmPassword: '',
    company: {
      status: 'prospecting',
      cashback: 0.1,
      discount_value: 0.1,
      discount_type: 'percentage'
    },
    companies: [],
    birthday: null,
    user: {
      balance: 0,
      role: '10cacf12-2e2c-416d-8d52-63baaea8baea',
      termos: false
    },
    rule: [(value) => !!value || 'Este campo é obrigatório.'],
    emailRules: [
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Preencha um email válido.';
      }
    ]
  }),
  methods: {
    async createUser() {
      // check all user fields, if any of them is empty, alert and return
      if (
        !this.user.full_name ||
        !this.user.document ||
        !this.user.email ||
        !this.user.password ||
        !this.confirmPassword ||
        !this.user.phone ||
        !this.user.company ||
        !this.birthday
      ) {
        alert('Preencha todos os campos');
        return;
      }

      try {
        this.loading = true;
        if (!this.$refs.form.validate()) {
          this.loading = false;
          return;
        }

        if (this.user.password !== this.confirmPassword) {
          alert('As senhas não coincidem');
          this.loading = false;
          return;
        }

        // const name = this.user.full_name?.toLowerCase().split(' ');
        // const formattedName = name?.map(
        //   (palavra) => palavra.charAt(0).toUpperCase() + palavra.slice(1)
        // );

        // this.user.first_name = formattedName && formattedName[0];
        // this.user.last_name = formattedName?.slice(1).join(' ');

        const name = this.user.full_name?.toLowerCase().split(' ');
        const formattedName = name?.map(
          (palavra) => palavra.charAt(0).toUpperCase() + palavra.slice(1)
        );

        const commonWords = [
          'Das',
          'Da',
          'Des',
          'De',
          'Dis',
          'Di',
          'Dos',
          'Do',
          'Dus',
          'Du'
        ];

        if (name) {
          if (
            formattedName?.length > 1 &&
            (commonWords.includes(formattedName[1]) ||
              formattedName[1].length === 1)
          ) {
            this.user.first_name = formattedName[0];
            this.user.last_name = formattedName.slice(1).join(' ');
          } else {
            this.user.first_name =
              formattedName?.length > 2
                ? `${formattedName[0]} ${formattedName[1]}`
                : formattedName[0];

            this.user.last_name =
              formattedName?.length > 2
                ? formattedName.slice(2).join(' ')
                : formattedName?.slice(1).join(' ');
          }
        } else {
          this.user.first_name = '';
          this.user.last_name = '';
        }

        this.user.email?.toLowerCase().replace(' ', '');

        if (this.birthday) {
          const birthday = this.birthday.split('/');
          // console.log(birthday);
          this.user.birthday = new Date(
            `${birthday[2]}/${birthday[1]}/${birthday[0]}`
          );
        }

        await axios.post('/users', this.user, {
          headers: {
            Authorization: `Bearer Df1Lordmn0PektIskl5GB5_PjnnVc4aV`
          }
        });

        this.vipCadastrado = true;
        this.user = {};
        this.$refs.form.reset();
        this.loading = false;
        setTimeout(() => {
          this.$router.push('/clubemezzo');
        }, 3000);
      } catch (error) {
        let errorMessage;
        error.response.data.errors
          ? (errorMessage = error.response.data.errors[0].message)
          : '';

        if (errorMessage === 'Field "email" has to be unique.') {
          alert('O email já esta cadastrado.');
          this.loading = false;
          return;
        }
        console.log(error);
        alert(
          'Ocorreu um erro ao fazer seu cadastro, por favor tente novamente.'
        );
        this.loading = false;
      }

      // const rawResponse = await fetch('https://gestao.abare.cloud/items/leads', {
      //   method: 'POST',
      //   headers: {
      //    "Content-Type": "application/json",
      //     "Accept": "application/json",
      //   },
      //   body: JSON.stringify({id_formulario: 16, lead: this.user})
      // });

      // else if (content.error =="ER_DUP_ENTRY") {
      //   alert("O CPF já esta cadastrado neste clube.")
      // }
      // else if (content.error == "ER_REQ_FIELD") {
      //   alert("Por favor preencha o campo CPF corretamente")
      // }
      // else {
      //   alert("Ocorreu um erro ao fazer seu cadastro, por favor tente novamente.")
      // }
    },
    async createCompany() {
      try {
        this.loading = true;

        const companyFormatted = this.company?.name
          ?.toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        const companyExists = this.companies.some(
          (el) => el.name === companyFormatted
        );

        if (companyExists) {
          alert('Empresa já cadastrada');
          return;
        }

        const {
          data: { data }
        } = await axios.post('/items/companies', this.company, {
          headers: {
            Authorization: `Bearer Df1Lordmn0PektIskl5GB5_PjnnVc4aV`
          }
        });

        this.companies.unshift(data);
        this.loading = false;
        this.formCreateCompany = false;
        this.companyAlreadyCreated = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getCompanies() {
      try {
        const {
          data: { data }
        } = await axios.get(
          '/items/companies?filter[status][_eq]=active&sort=name',
          {
            headers: {
              Authorization: `Bearer Df1Lordmn0PektIskl5GB5_PjnnVc4aV`
            }
          }
        );

        this.companies = data;
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.getCompanies();
  }
};
</script>
