<template>
  <v-row style="height: 101vh" justify="center" align="center" class="bgc3">
    <v-col cols="12" md="12" class="d-flex align-center flex-column" style="">
      <v-card style="background-color: #8e1f3ec7; padding: 20px">
        <h1 style="color: #fff; font-family: exo2; font-weight: 500">
          Bem vindo<b style="text-transform: uppercase" v-if="user"
            >, {{ user.first_name }}</b
          >
        </h1>

        <h2 style="color: #fff; font-family: exo2; font-weight: 500">
          Cadastre uma nova senha
        </h2>

        <v-form class="mt-5">
          <v-text-field
            v-model="dado.novaSenha"
            outlined
            dark
            :append-icon="mostrarNovaSenha ? 'mdi-eye-off' : 'mdi-eye'"
            :type="mostrarNovaSenha ? 'text' : 'password'"
            @click:append="mostrarNovaSenha = !mostrarNovaSenha"
            placeholder="Nova senha"
          ></v-text-field>
          <v-text-field
            v-model="dado.confirmaSenha"
            outlined
            dark
            :append-icon="mostrarConfirmaSenha ? 'mdi-eye-off' : 'mdi-eye'"
            :type="mostrarConfirmaSenha ? 'text' : 'password'"
            @click:append="mostrarConfirmaSenha = !mostrarConfirmaSenha"
            placeholder="Confirme sua senha"
          ></v-text-field>
          <v-text-field
            v-if="user"
            outlined
            dark
            v-model="dado.birthday"
            v-mask="'##/##/####'"
            placeholder="Data de nascimento"
            color="#8e1f3f"
          ></v-text-field>

          <v-btn
            @click="criarSenha"
            block
            style="color: #8e1f3f; background-color: #ffffffce"
            >Salvar</v-btn
          >
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.bgc3 {
  background-image: url('../assets/bannerStatic.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>

<script>
import axios from 'axios';
import store from '../store';

export default {
  data: () => ({
    dado: {
      novaSenha: '',
      confirmaSenha: '',
      birthday: ''
    },
    user: store.state.user || null,
    mostrarNovaSenha: false,
    mostrarConfirmaSenha: false
  }),

  beforeMount() {
    // this.getUserData();
  },
  methods: {
    async getUserData() {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + this.$route.query.token;

      await axios.post('/sign-in/token/verify');

      await this.$store.dispatch('getUserInfo');

      this.user = this.$store.state.user;
    },
    formatDate(birthday) {
      const formattedBirthday = birthday.split('/');
      return `${formattedBirthday[2]}-${formattedBirthday[1]}-${formattedBirthday[0]}`;
    },
    async criarSenha() {
      try {
        if (this.dado.novaSenha === '') {
          alert('Preencha uma nova senha');
          return;
        }

        if (this.dado.novaSenha !== this.dado.confirmaSenha) {
          alert('Senhas digitadas não correspondem');
          return;
        }

        if (this.user) {
          if (this.dado.birthday === '' || this.dado.birthday.length <= 9) {
            alert('Preencha uma data de nascimento válida');
            return;
          }
        }

        const token = this.$route?.query?.token;
        const id = this.$store?.state?.user?.id;

        if (token) {
          await axios.post(
            '/auth/password/reset',
            {
              token,
              password: this.dado.novaSenha
            },
            {
              headers: {
                Authorization: null
              }
            }
          );
        } else if (id) {
          const birthday = this.formatDate(this.dado.birthday);

          await axios.patch(
            `/users/${id}`,
            {
              password: this.dado.novaSenha,
              birthday: new Date(birthday)
            },
            {
              headers: {
                Authorization: `Bearer Df1Lordmn0PektIskl5GB5_PjnnVc4aV`
              }
            }
          );
        } else {
          throw new Error('Token expirado');
        }

        alert('Senha criada com sucesso');
        this.$router.push('/clubemezzo');
      } catch (error) {
        console.log(error);
        alert('Erro ao cadastrar nova senha');
      }
    }
  }
};
</script>

