var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"bgMezzo mx-0",attrs:{"id":"showMembers","fluid":""}},[_c('v-row',{staticClass:"mt-11 pt-10 mx-0",attrs:{"id":"clubeVip"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Procurar por nome, telefone, email...","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.clubeVip,"items-per-page":15,"item-key":"id","search":_vm.search,"loading":_vm.loading,"show-expand":"","expanded":_vm.expanded,"loading-text":"Carregando... Um instante por favor."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.lead.telefone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lead.telefone)+" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openWhatsapp(item.lead.telefone)}}},[_c('v-icon',[_vm._v("mdi-whatsapp")])],1)]}},{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatISODate")(item.date_created))+" ")]}},{key:"item.lead.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lead.nome.split(" ")[0])+" ")]}},{key:"item.lead.mensagem",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lead.mensagem.length > 0 ? item.lead.mensagem : "-")+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-3",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.lead.nome)),_c('br'),_c('b',[_vm._v("Telefone:")]),_vm._v(" "+_vm._s(item.lead.telefone)+" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openWhatsapp(item.lead.telefone, item.lead.nome)}}},[_c('v-icon',[_vm._v("mdi-whatsapp")])],1),_c('br'),_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(item.lead.email)+" ")],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }