<template>
  <v-container id="showMembers" class="bgMezzo mx-0" fluid>
    <v-row class="mt-11 pt-10 mx-0" id="clubeVip">
      <v-col>
        <v-text-field
          v-model="search"
          label="Procurar por nome, telefone, email..."
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="clubeVip"
          :items-per-page="15"
          class="elevation-1 "
          item-key="id"
          :search="search"
          :loading="loading"
          show-expand
          :expanded.sync="expanded"


          loading-text="Carregando... Um instante por favor."
        >
          <!-- <template v-slot:item.showUtilizado="{ item }">
            <b>{{ getRestantes(item.usos) }}</b>
          </template> -->

          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-chip small v-for="d in dtUsos(item.lead.usos)" :key="d">
              {{ d | formatISODate }}
            </v-chip>
            <v-btn
              class="ml-1"
              x-small
              @click="criarVoucher(item)"
            >
              <v-icon small class="mr-2">
                mdi-folder-plus
              </v-icon>
              Marcar Uso
            </v-btn> -->
            <!-- <v-btn
              v-show="!(getRestantes(item.dt_uso) === 0)"
              class="ml-1"
              x-small
              @click="marcarUso(item)"
            >
                <v-icon 
                    small 
                    class="mr-2"
                >
                    mdi-plus
                </v-icon>
              Usar
            </v-btn> -->
          <!-- </template> -->
          <template v-slot:[`item.lead.telefone`]="{ item }">
          {{item.lead.telefone}}
            <v-btn icon small @click="openWhatsapp(item.lead.telefone)">
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
         </template>
         
          <template v-slot:[`item.date_created`]="{ item }">
            {{ item.date_created | formatISODate }}
          </template>
          <template v-slot:[`item.lead.nome`]="{item}">
            {{ item.lead.nome.split(" ")[0] }}
          </template>
          <template v-slot:[`item.lead.mensagem`]="{item}">
             {{ item.lead.mensagem.length > 0 ? item.lead.mensagem : "-"}}
          </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-3">
                {{item.lead.nome}}<br>
                <b>Telefone:</b>  {{item.lead.telefone}}
                <v-btn icon small @click="openWhatsapp(item.lead.telefone, item.lead.nome)">
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn><br>
                <b>Email:</b> {{item.lead.email}}
              </td>
            </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.voucher { 
  background-color: #72c5d3;
  color:white;
  padding:3px
}
.bgMezzo {
background: rgb(52,0,15);
background: linear-gradient(180deg, rgba(52,0,15,1) 0%, rgba(142,31,63,1) 5%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 100%);
  min-height:100vh;
}


</style>
<script>
export default {
  name: "ShowMembers",
  data: () => ({
    select: "Clube VIP",
    search: null,
    expanded: [],
    headers: [
      {
        text: "Dt. Cadastro",
        value: "date_created",
        width: "100px",
        align: "center",
      },
      { text: "Nome", value: "lead.nome" },
      { text: "Mensagem", value: "lead.mensagem", sortable: false  },
      { text: "Recomendaria", value: "lead.range1"},
      { text: "Atendimento", value: "lead.range2" },
      { text: "Comida", value: "lead.range3" },
      { text: "Bebida", value: "lead.range4" },
      { text: "Estrutura", value: "lead.range5" },
      { text: "Preço", value: "lead.range6" },
      { text: "Como", value: "lead.como"},
      { text: "Outros", value: "lead.outro"},
    ],
    clubeVip: [],
    loading: false,
  }),
  created() {
    this.loading = true;
    fetch(
        "https://gestao.abare.cloud/items/leads?filter[id_formulario][_eq]=14&sort=-date_created&limit=9000",
      {
        headers: {
          Authorization: "Bearer QaIIzDCLgYdRQKxLVhLpEeQv81VnBw1awkOc",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.clubeVip = data.data;
        this.loading = false;
      });
  },
  methods: {
    getRestantes(u) {
      return (
        (this.select == "Clube VIP" ? 1 : 10) -
        (u != null ? u.split(",").length : 0)
      );
    },
    dtUsos(u) {
      return u != null ? u : [];
    },
    openWhatsapp(intTelefone, strNome) {
      let strMensagem =
        "Olá "+ strNome ;
      window.open(
        "https://api.whatsapp.com/send?phone=" +
          intTelefone +
          "&text=" +
          encodeURIComponent(strMensagem)
      );
    },
    criarVoucher(l) {
      if (confirm("Você deseja marcar uso de " + l.lead.nome)) {
        let dtNowHuman = new Date().toISOString().slice(0, 11)+" "+new Date().toLocaleTimeString('pt-BR');
        if (l.lead.usos === undefined) { 
          l.lead.usos = [dtNowHuman]
        }
        else { 
          l.lead.usos.push(dtNowHuman)
        }
        l.lead.dt_uso = dtNowHuman
        fetch("https://gestao.abare.cloud/items/leads/" + l.id, {
          method: "PATCH", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer QaIIzDCLgYdRQKxLVhLpEeQv81VnBw1awkOc",
          },
          body: JSON.stringify(l),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.data.id) {
              window.alert("Marcado uso para"+ l.lead.nome +" em " + dtNowHuman);
              this.$forceUpdate();
            }
          });
      }
    },
  },
};
</script>
