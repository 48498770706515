<template>
	<v-container fluid style="padding: 0; background-color: #f6f4ee">
		<v-app-bar style="background-color: #fff; box-shadow: none !important">
			<v-row id="cadastro" style="padding: 10px 60px">
				<v-col
					cols="12"
					md="4"
					class="d-flex justify-center alignMob flex-column"
				>
					<v-img 
						src="../assets/lpCopa/logo.png"
						width="100"
						contain
					></v-img>
				</v-col>
				<v-col v-if="intBreakPoint > 2"
					cols="6"
					md="8"
					style="gap: 2rem"
					class="d-flex justify-end align-center"
				>
					<span
						style="
							font-family: exo2;
							font-weight: 500;
							color: #801232;
						"
						>Harmonizza</span
					>
				
					<a href="#cardapio">
						<span
							style="
								font-family: exo2;
								font-weight: 500;
								color: #801232;
							"
							>Cardápio</span
						>
					</a>
					<a href="#localizacao">
						<span
							style="
								font-family: exo2;
								font-weight: 500;
								color: #801232;
							"
							>Localização</span
						></a
					>
				</v-col>
			</v-row>
		</v-app-bar>
		<v-row style="" class="padMob alturaMob"> 
			<v-col
				cols="12"
				md="7"
				class="bgc1 d-flex align-center justify-center flex-column"
			>
				<div class="d-flex align-center justify-center flex-column" style="background-color: rgb(0 0 0 / 50%); padding: 20px;">
					
					<p
						class="mt-5 fontGrossa mobileSpace"
						style="
							font-family: exo2;
							color: #fff;
							font-weight: 600;
							text-align: left;
							line-height: 1.2;
							font-size: 2rem;
						"
					>
						Que tal participar de uma experiência <br />
						exclusiva de uma harmonização de um <br />
						<b> jantar completo </b> com
						<b> cervejas artesanais.</b>
					</p>
				</div>
			</v-col>
			<v-col
				cols="12"
				md="5"
				class="d-flex align-center justify-center flex-column"
				style="background-color: #801232"
			>
			<v-img class="sizeImg mt-10"
						src="../assets/harmonizza.png"
						contain
						width="300"
					></v-img>
				<h1 style="font-family: exo2; color: #fff; font-weight: 300">
					Faça sua reserva
				</h1>
				<h1 style="font-family: exo2; font-weight: 800; color: #fff">
					26/04 às 19h
				</h1>

				<v-form
					class="mt-10 d-flex flex-column align-center"
					style="width: 100%; color: #333 !important"
				>
					<v-text-field
						v-model="contato.nome"
						outlined
						light
						color="#333"
						style="width: 90%; "
						background-color="#fff"
						placeholder="Nome"
					></v-text-field>
					<v-text-field
						outlined
						style="width: 90%; "
						v-model="contato.email"
						background-color="#fff"
						placeholder="E-mail"
					></v-text-field>
					<v-text-field
						outlined
						style="width: 90%; "
						v-model="contato.telefone"
						background-color="#fff"
                        v-mask="'(##) # #### ####'"
						placeholder="Telefone"
					></v-text-field>
					<v-spacer></v-spacer>
					<v-btn  @click="enviarFormulario"  class="btn mgBottom" style="width: 18vw; height: 10vh; margin-bottom: 20px;"
						>
						<v-icon>mdi-whatsapp</v-icon>
						Garanta sua vaga</v-btn
					>
				</v-form>
			</v-col>
		</v-row>

		<v-row class="mt-10 padMob alturaMob" id="parceiro" style="padding: 90px 60px;">
			<v-col
			style="margin-bottom: 50px;"
				cols="12"
				md="6"
				offset-md="3"
				class="d-flex justify-center flex-column align-center"
			>
				<v-img src="../assets/Grupo 68.png" contain width="70%"></v-img>
			</v-col>
			
			<v-col
				cols="12"
				md="6"
				class="d-flex align-center justify-center  mgBottom"
				
			>
			<div class="d-flex directionMob" style="gap: 1rem">
				<img src="../assets/logo menor.png" width="130" />
				<div class="d-flex flex-column">
					<span
						style="
							font-family: exo2;
							color: #801232;
							font-weight: 400;
							text-align: left;
							font-size: 1.3rem;
							line-height: 1.2;
						"
					>
						Meio bar meio restaurante <br />
						combinando o que há de <br />
						melhor nos dois
					</span>
					<a
						href="https://www.instagram.com/mezzobarsjc/"
						target="_blank"
					>
						<v-btn class="btn2 mt-2">
							<v-icon color="white">mdi-instagram</v-icon>
							Mezzo</v-btn
						>
					</a>
				</div>
			</div>
			</v-col>
			<v-col
				cols="12"
				md="6"
				class="d-flex align-center justify-center "
				
			>
			<div class="d-flex directionMob" style="gap: 1rem">
				<div style="height:; " class="d-flex align-center justify-center flex-column">
				<img src="../assets/Grupo 60.png" width="250" />
			</div>
				<div class="d-flex flex-column">
					<span
						style="
							font-family: exo2;
							color: #801232;
							font-weight: 400;
							text-align: left;
							font-size: 1.3rem;
							line-height: 1.2;
						"
					>
					A BROWe! nasceu da paixão de dois <br>
amigos pela cerveja, com a missão <br>
de fortalecer e impulsionar o mercado<br>
cervejeiro no Vale do Paraíba.
					</span>
					<a
						href="https://www.instagram.com/browecervejaria/"
						target="_blank"
					>
						<v-btn class="btn2 mt-2">
							<v-icon color="white">mdi-instagram</v-icon>
							Browe</v-btn
						>
					</a>
				</div>
			</div>
			</v-col>
		</v-row>

		<v-row style="border-top: 18px solid #801232" class="">
			<v-col cols="4" md="4" style="padding: 0px !important">
				<v-img src="../assets/mez1.jpg"></v-img>
			</v-col>

			<v-col cols="4" md="4" style="padding: 0px !important">
				<v-img src="../assets/mez2.jpg"></v-img>
			</v-col>

			<v-col cols="4" md="4" style="padding: 0px !important">
				<v-img src="../assets/mez3.jpg"></v-img>
			</v-col>
		</v-row>

		<v-row id="cardapio" style="padding: 0 60px" class="padMob">
			<v-col cols="12" md="12">
				<h1 class="titleMob"
					style="
						font-family: exo2;
						color: #801232;
						font-weight: 400;
						font-size: 6rem;
						text-align: center;
					"
				>
					Cardápio
				</h1>

				<p style="color: #801232"><b>WELCOME DRINK:</b> Cerveja Gose</p>

				<p style="color: #801232">
					<b>ENTRADA DUO:</b> Steak tartar + Brusqueta Caprese
					harmonizados com German Pils e Witbier
				</p>

				<p style="color: #801232">
					<b>PRATO PRINCIPAL:</b> Rigatoni com Ragu de Costela e molho
					de tomate da casa harmonizado com Session Ipa
				</p>

				<p style="color: #801232"><b>SOBREMESA:</b> Panacotta de café e calda de chocolate harmonizada com Joselita Sour - Frutas Vermelhas</p>

				<p style="color: #801232; font-size: 1.3rem">
					<b>INGRESSO: R$150/Pessoa</b>
				</p>
			</v-col>
			<v-col cols="12" md="12" class="d-flex justify-center">
				<a href="#cadastro">
					<v-btn large class="btn" style="">Faça sua reserva</v-btn>
				</a>
			</v-col>
		</v-row>

		<v-row
			id="localizacao"
			class="bgc3 padMob"
			style="border-top: 18px solid #801232; padding: 0 60px"
		>
			<v-col cols="12" md="12">
				<h1 class="titleMob"
					style="
						font-family: exo2;
						color: #801232;
						font-weight: 400;
						font-size: 6rem;
						text-align: center;
					"
				>
					Localização
				</h1>
			</v-col>

			<v-col cols="12" md="6">
				<v-img src="../assets/Imagem 1.png" width="50vw"></v-img>
			</v-col>

			<v-col cols="12" md="6" class="d-flex justify-center flex-column">
				<p
					style="
						font-family: exo2;
						color: #801232;
						font-weight: 400;
						font-size: 1.8rem;
					"
				>
					Avenida Cassiano Ricardo, 319 -Mezanino - <br />
					Jardim Aquarius, São José dos Campos - SP
				</p>
				<p
					style="
						font-family: exo2;
						color: #801232;
						font-weight: 400;
						font-size: 1.9rem;
					"
				>
					Data: <b>26/04 às 19h</b>
				</p>
				<a href="#cadastro">
					<v-btn large class="btn" style="">Faça sua reserva</v-btn>
				</a>
			</v-col>
		</v-row>
		<v-row style="background-color: #801232">
			<v-col
				cols="12"
				class="d-flex justify-center align-center directionMob"
				style="gap: 2rem"
			>
				<img src="../assets/abareBranco.png" width="40vw" />
				<span style="font-family: exo2; color: #fff; font-weight: 400"
					>Desenvolvido por Abaré Marketing e Tecnologia</span
				>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>

@media (min-device-width: 300px) and (max-device-width: 731px) {
	.btn{
		width: 63vw !important;
	}
	.mobileSpace{
		padding: 60px 0;
	}
    .alignMob{
        align-items: center !important;
    }
    .directionMob{
        flex-direction: column !important;    
    }

    .mgBottom{
        margin-bottom: 30px !important;
    }
    .alturaMob{
        height: auto !important;
    }

    .fontGrossa{
        font-weight: 600 !important;
    }
    .padMob{
        padding: 15px !important;
    }
    .sizeImg{
        width: 290px !important;
    }
    .titleMob{
        font-size: 3rem !important;
    }
}
	.bgc3 {
		background-image: url("../assets/footer.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	.btn2 {
		font-family: exo2 !important;
		font-size: 1.4rem;
		color: #fff !important;
		font-weight: 300 !important;
		background-color: #801232 !important;
		text-transform: initial !important;
		letter-spacing: normal !important;
	}
	.btn {
		font-family: exo2 !important;
		font-size: 1.4rem;
		color: #fff !important;
		font-weight: 300 !important;
		background-color: #23b95b !important;
		text-transform: initial !important;
		letter-spacing: normal !important;
	}
	.bgc1 {
		background-image: url("../assets/Grupo 67.png");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
</style>

<script>
	export default {
		data: () => ({
			dialog: false,
			contato: { nome: "", email: "", telefone: "" },
		}),

		computed: {
			isLandingPage() {
				let lps = ["Harmonizza"];
				return lps.indexOf(this.$route.name) > -1;
			},

            intBreakPoint() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 0;
					case "sm":
						return 1;
					case "md":
						return 2;
					case "lg":
						return 3;
					case "xl":
						return 4;
					default:
						return 0;
				}
			},
		},

		methods: {
			enviarFormulario() {
			if(this.contato.nome == '' || this.contato.email == '' || this.contato.telefone == ''){
				window.alert('Preencha todos os campos corretamente.')
			}
			else{
				fetch("https://gestao.abare.cloud/items/leads", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
					},
					body: JSON.stringify({
						id_formulario: 220,
						lead: this.contato,
					}),
				})
					.then((response) => response.text())
					.then((data) => {
						window.console.log(data);
						// alert("contato enviado com sucesso");
						window.location.href = "https://wa.me/5512996412263?text=Eu%20quero%20participar%20do%20Mezzo%20Harmonizza"
						// window.open(
						// 	"https://wa.me/5512996412263?text=Eu%20quero%20participar%20do%20Mezzo%20Harmonizza"
						// );
						// this.snackbar = true;
						// this.enviarEmail()
					});
				}
			},
		},
	};
</script>
