<template>
  <v-container id="showMembers" class="bgMezzo mx-0" fluid>
    <v-row class="mt-11 pt-10 mx-0" id="clubeVip">
      <v-col cols="10" md="10">
        <v-text-field v-if="intBreakPoint > 2" light v-model="search" label="Procurar por nome, cpf, email..."
          outlined />
        <v-text-field v-if="intBreakPoint < 2" dark v-model="search" label="Procurar por nome, cpf, email..."
          outlined />
      </v-col>
      <v-col cols="2" md="2" style="margin-top: 10px" class="d-flex justify-center">
        <v-btn class="btnExport" @click="exportarLeads">
          <v-icon class="white--text" color="white">mdi-file-excel</v-icon>
          Exportar
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <v-data-table :headers="headers" :items="clubeVip" :items-per-page="15" class="elevation-1 " item-key="celular"
          :search="search" :loading="loading" loading-text="Carregando... Um instante por favor.">
          <!-- <template v-slot:item.showUtilizado="{ item }">
            <b>{{ getRestantes(item.usos) }}</b>
          </template> -->

          <template v-slot:[`item.actions`]="{ item }">
            <v-chip small v-for="d in dtUsos(item.lead.usos)" :key="d">
              {{ d | formatISODate }}
            </v-chip>
            <v-btn class="ml-1 btnAction" x-small @click="criarVoucher(item)">
              <v-icon small class="mr-2">
                mdi-folder-plus
              </v-icon>
              Marcar Uso
            </v-btn>
            <!-- <v-btn
              v-show="!(getRestantes(item.dt_uso) === 0)"
              class="ml-1"
              x-small
              @click="marcarUso(item)"
            >
                <v-icon 
                    small 
                    class="mr-2"
                >
                    mdi-plus
                </v-icon>
              Usar
            </v-btn> -->
          </template>
          <!-- <template v-slot:[`item.lead.telefone`]="{ item }"> -->
          <!-- {{ item.lead ? "-" : item.lead.telefone}} -->
          <!-- <v-btn icon small @click="openWhatsapp(item.lead ? "-" : item.lead.telefone)">
              <v-icon> mdi-whatsapp</v-icon>
            </v-btn> -->
          <!-- </template> -->
          <template v-slot:[`item.date_created`]="{ item }">
            {{ item.date_created | formatISODate }}
          </template>
          <!-- <template v-slot:item.lead.status="{item}">
            <span :class="(item.lead.status ==='voucher') ? 'voucher' : ''">
              {{ item.lead.status }}
            </span>
          </template> -->

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>


<style scoped>
.btnExport{
  background-color: #6ebf68 !important;
  color: #fff !important;
}

@media (min-device-width: 360px) and (max-device-width: 853px) {
  .v-data-table__mobile-row {
    height: initial;
    min-height: 48px;
    display: grid !important;
    padding: 11px 10px !important;
  }

  .v-data-table__mobile-row {
    align-items: center;
    display: grid !important;
    justify-content: space-between;
    padding: 15px 10px !important;
  }
}

.btnAction {
  background-color: #6ebf68 !important;
  font-size: 13px !important;
  color: #fff !important;
  padding: 15px !important;
  border-radius: 50px !important;
}

.voucher {
  background-color: #72c5d3;
  color: white;
  padding: 3px
}

.bgMezzo {
  background: rgb(52, 0, 15);
  background: linear-gradient(180deg, rgba(52, 0, 15, 1) 0%, rgba(142, 31, 63, 1) 5%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 1) 100%);
  min-height: 100vh;
}
</style>


<script>
export default {
  name: "ShowMembers",
  data: () => ({
    select: "Clube VIP",
    search: null,
    headers: [
      {
        text: "Dt. Cadastro",
        value: "date_created",
        width: "100px",
        align: "center",
      },
      { text: "Nome", value: "lead.nome" },
      { text: "Empresa", value: "lead.empresaNome" },
      { text: "Celular", value: "lead.telefone", width: "140px", sortable: false },
      { text: "Email", value: "lead.email" },

      { text: "CPF", value: "lead.cpf", width: "130px" },
      {
        text: "Ações",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
    clubeVip: [],
    loading: false,
  }),
  created() {
    this.loading = true;
    fetch(
      "https://gestao.abare.cloud/items/leads?filter[id_formulario][_eq]=39&sort=-date_created&limit=3000",
      {
        headers: {
          Authorization: "Bearer QaIIzDCLgYdRQKxLVhLpEeQv81VnBw1awkOc",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.clubeVip = data.data;
        this.loading = false;
      });
  },

  computed: {

    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    }
  },

  methods: {
    exportarLeads() {
      window.open("https://gestao.abare.cloud/export-xls/39")
    },

    getRestantes(u) {
      return (
        (this.select == "Clube VIP" ? 1 : 10) -
        (u != null ? u.split(",").length : 0)
      );
    },
    dtUsos(u) {
      return u != null ? u : [];
    },
    openWhatsapp(intTelefone) {
      let strMensagem =
        "Olá, estamos te esperando no Mezzo Bar ";
      window.open(
        "https://api.whatsapp.com/send?phone=" +
        intTelefone +
        "&text=" +
        encodeURIComponent(strMensagem)
      );
    },
    criarVoucher(l) {
      if (confirm("Você deseja marcar uso de " + l.lead.nome)) {
        let dtNowHuman = new Date().toISOString().slice(0, 11) + " " + new Date().toLocaleTimeString('pt-BR');
        if (l.lead.usos === undefined) {
          l.lead.usos = [dtNowHuman]
        }
        else {
          l.lead.usos.push(dtNowHuman)
        }
        l.lead.dt_uso = dtNowHuman
        fetch("https://gestao.abare.cloud/items/leads/" + l.id, {
          method: "PATCH", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer QaIIzDCLgYdRQKxLVhLpEeQv81VnBw1awkOc",
          },
          body: JSON.stringify(l),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.data.id) {
              window.alert("Marcado uso para" + l.lead.nome + " em " + dtNowHuman);
              this.$forceUpdate();
            }
          });
      }
    },
  },
};
</script>
